<script setup lang="ts">
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import Rating from './Rating.vue'
import { Review } from '@interfaces/review.interface'

defineProps<{
    review: Review,
}>()

const { t } = useI18n()
</script>

<template>
    <figure class="p-8 leading-6 text-p2 rounded-2xl bg-grayscale-50">
        <blockquote class="text-grayscale-900">
            <p>“{{ review.message }}”</p>
        </blockquote>

        <figcaption class="mt-6">
            <div>
                <Rating :rating="review.rating" />
                <p class="sr-only">{{ t('components.review.stars-count', { count: review.rating }) }}</p>
            </div>

            <div class="mt-1.5">
                <span class="font-medium text-grayscale-900">{{ review.name ?? t('components.review.anonymous') }}</span>,
                <time :datetime="dayjs(review.created_at).format('YYYY-MM-DD')">{{ dayjs(review.created_at).format('D MMM YYYY') }}</time>
            </div>
        </figcaption>
    </figure>
</template>
