<script setup lang="ts">
import { Link } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'
import ReviewCard from '../../../Components/UI/ReviewCard.vue'
import { ArrowRightIcon } from '@heroicons/vue/20/solid'

defineProps<{
    reviews: Array<any>
}>()

const { t } = useI18n()
</script>

<template>
    <section class="pt-24 pb-16 bg-white">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="sm:flex sm:items-baseline sm:justify-between">
                <h2 class="font-medium text-grayscale-900 text-h5">{{ t('pages.home.latest-reviews.title') }}</h2>
                <Link
                    :href="route('reviews', { type: 'shop' })"
                    class="hidden font-medium text-p2 text-primary-600 hover:text-primary-800 sm:inline-flex sm:items-center group"
                >
                {{ t('pages.home.latest-reviews.all-reviews') }}
                <ArrowRightIcon class="w-4 h-4 ml-1 transition-transform duration-300 transform group-hover:translate-x-0.5" />
                </Link>
            </div>

            <div class="flow-root mx-auto mt-6">
                <div class="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
                    <div
                        v-for="review in reviews"
                        :key="review.id"
                        class="pt-8 sm:inline-block sm:w-full sm:px-4"
                    >
                        <ReviewCard :review="review" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
